<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <section class="section is-main-section">
      <card-component
        :title="formCardTitle"
        icon="file-edit"
        class="tile is-child"
      >
        <sale-offers-announcements-add
          @handleAddAnnouncement="handleAddAnnouncement"
          @handleEditAnnouncement="handleEditAnnouncement"
        />
      </card-component>
    </section>
  </div>
</template>

<script>
import { pathOr } from "ramda";
import Announcements from "@/services/announcements.service";
import TitleBar from "@/components/TitleBar";
import CardComponent from "@/components/CardComponent";
import SaleOffersAnnouncementsAdd from "../components/announcements/saleOffers/saleOffersAnnouncements-add.vue";

export default {
  name: "SaleOffersAnnouncement",
  components: { TitleBar, CardComponent, SaleOffersAnnouncementsAdd },
  data() {
    return {
      editMode: false,
    };
  },
  methods: {
    async handleAddAnnouncement(announcement) {
      const { handleAddAnnouncement } = Announcements;
      const response = await handleAddAnnouncement(announcement);
      const { data, status } = response;
      if (status == "200") {
        this.$router.push({ path: "/SaleOffersAnnouncements" });
      } else {
      }
    },
    async handleEditAnnouncement(announcement) {
      const { handleEditAnnouncement } = Announcements;
      const response = await handleEditAnnouncement(announcement);
      this.$router.push({ path: "/SaleOffersAnnouncements" });
    },
  },
  computed: {
    titleStack() {
      if (this.editMode) {
        return ["Oferte de vanzare", "Editare oferta de vanzare"];
      } else {
        return ["Oferte de vanzare", "Adaugare oferta de vanzare"];
      }
    },
    formCardTitle() {
      if (this.editMode) {
        return "Editare oferta de vanzare";
      } else {
        return "Adaugare oferta de vanzare";
      }
    },
  },
  created() {
    const id = parseInt(pathOr(null, ["params", "id"], this?.$route));
    if (id) {
      this.editMode = true;
    } else {
    }
  },
};
</script>
