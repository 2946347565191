<template>
  <div>
    <ValidationObserver ref="form">
      <div class="columns">
        <div class="column">
          <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
            <b-field
              :type="errors[0] ? 'is-danger' : ''"
              label="Oferta de vanzare"
              :message="errors[0]"
            >
              <b-input v-model="announcement.name" required />
            </b-field>
          </ValidationProvider>
        </div>
        <div class="column">
          <b-field label="Document">
            <b-field class="file is-primary" :class="{ 'has-name': !!file }">
              <b-upload v-model="file" class="file-label" rounded>
                <span class="file-cta">
                  <b-icon class="file-icon" icon="upload"></b-icon>
                  <span class="file-label">{{
                    file.name || "Click pentru a incarca documentul dorit"
                  }}</span>
                </span>
              </b-upload>
            </b-field>
          </b-field>
        </div>
      </div>
    </ValidationObserver>
    <div class="columns">
      <div class="column">
        <b-field grouped>
          <div class="control">
            <b-button
              type="is-primary"
              native-type="submit"
              @click="handleAddAnnouncement"
              >Salvare</b-button
            >
          </div>
          <div class="control">
            <b-button type="is-danger is-outlined" @click="cancel()"
              >Anulare</b-button
            >
          </div>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import { rawObject } from "@/helpers/utils";
import { pathOr } from "ramda";
import Announcements from "@/services/announcements.service";
import BlobsService from "@/services/blobs.service";
export default {
  name: "SaleOffersAnnouncementsAdd",
  props: {
    accept: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      editMode: false,
      id: 0,
      file: {
        name: null,
      },
      announcement: {
        name: null,
        date: null,
        uri: null,
        announcementTypeId: 2,
      },
    };
  },
  methods: {
    async uploadFile() {
      const { handleUploadSaleOffersAnnouncement } = BlobsService;
      await handleUploadSaleOffersAnnouncement(this.file).then((response) => {
        const { data } = response;
        this.announcement.uri = data;
        return data;
      });
    },
    async deleteFile(fileName) {
      const { handleDelete } = BlobsService;
      const response = await handleDelete(fileName);
      const { data } = response;
      this.announcement.uri = null;
      return data;
    },
    handleAddAnnouncement() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }
        this.announcement.date = new Date(
          this.announcement.date.getTime() -
            this.announcement.date.getTimezoneOffset() * 60000
        ).toJSON();
        if (this.editMode) {
          if (this.file.name != this.announcement.uri) {
            let fileToBeDeleted = this.getFileNameFromBlob(
              this.announcement.uri
            );
            this.deleteFile(fileToBeDeleted);
            this.uploadFile().then((response) => {
              this.$emit(
                "handleEditAnnouncement",
                rawObject(this.announcement)
              );
            });
          }
        } else {
          this.uploadFile().then((response) => {
            this.$emit("handleAddAnnouncement", rawObject(this.announcement));
          });
        }
      });
    },
    async getAnnouncement(id) {
      const { handleGetAnnouncement } = Announcements;
      const response = await handleGetAnnouncement(id);
      const { data } = response;
      this.file.name = this.getFileNameFromBlob(data.uri);
      return data;
    },
    cancel() {
      if (this.editMode == false && this.announcement.uri != null) {
        this.deleteFile(this.file.name);
      }
      this.$router.push({ path: "/SaleOffersAnnouncements" });
    },
  },
  created() {
    const id = parseInt(pathOr(null, ["params", "id"], this?.$route));
    this.id = id;
    if (this.id) {
      this.editMode = true;
      this.getAnnouncement(id).then((response) => {
        this.announcement = response;
      });
    } else {
      this.announcement.date = new Date();
    }
  },
};
</script>
